import abTestingServerState, { TAbTestingState } from '@/utils/ABTesting/context/serverState';
import botServerState, { TBotState } from '@/utils/Bots/context/serverState';
import catalogModeServerState, { TCatalogModeState } from '@/utils/CatalogMode/context/serverState';
import rootHomepageServerState, { TRootHomepageState } from '@/utils/RootHomepage/context/serverState';
import featureFlagServerState, { TFeatureFlagState } from '@/utils/FeatureFlags/context/serverState';
import { IRegisteredState } from '@/lib/context/appServerState/types';

export type TRegisteredStates = TBotState &
    TAbTestingState &
    TCatalogModeState &
    TRootHomepageState &
    TFeatureFlagState;

export const registeredStates: Array<IRegisteredState<any, any>> = [
    abTestingServerState,
    botServerState,
    catalogModeServerState,
    rootHomepageServerState,
    featureFlagServerState,
];
