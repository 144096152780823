import { IRegisteredState } from '@/lib/context/appServerState/types';
import { MiddlewareSettingsKeysEnum } from '@/utils/middleware/applySettings';
import { decodeSegment } from '@/utils/FeatureFlags/utils/decodeEncodeSegment';

export type TFeatureFlagState = typeof defaultState;

const defaultState: {
    featureFlagsSegment?: Array<boolean>;
} = {
    featureFlagsSegment: undefined,
};

const setter = (state: TFeatureFlagState, settingsValue: string) => {
    const segment = Number(settingsValue.split('s')[1]);
    if (segment) {
        state.featureFlagsSegment = decodeSegment(segment);
    }
};

const serverState: IRegisteredState<TFeatureFlagState, string> = {
    defaultState,
    id: MiddlewareSettingsKeysEnum.FEATURE_FLAGS,
    setter,
};

export default serverState;
