/**
 * The state of active/inactive features flag represents ordered by id list of booleans (e.x. [true, false, true, true]: Array<bool>).
 * This gives the possibility to represent the state as single-bit digits (e.x. [1, 0, 1, 1]: Array<number>).
 * As a result, we can convert order state to the segment by encoding/decoding binary radix to decimal, aka segments: (e.x."1011" => "5").
 * To have the possibility to manage a state with a single flag, we need to add "1" to the begging of the bi-digit to ensure correct encoding
 */

export const decodeSegment = (seg: number): Array<boolean> =>
    seg
        .toString(2)
        .substring(1)
        .split('')
        .map((item) => item === '1');
export const encodeSegment = (data: Array<number>): number => parseInt('1' + data.join(''), 2);
